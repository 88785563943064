
.headerContainer {
    display: flex;
    align-items: center;
    padding: 30px 0;
    @media (max-width: 700px) {
        padding: 30px 0 0 0;
        justify-content: space-between;
    }
}

.logo {
    position: relative;
    z-index: 99;
}

.nav {
    margin: 0 auto;
    @media (max-width: 700px) {
        display: none;
    }
}

.list {
    display: flex;
    align-items: center;
    gap: 10px;
}

.link {
    position: relative;
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.6px;
    color: #B3B3B3;
    transition: color 0.6s ease-in-out;

    &:before {
        position: absolute;
        content: '';
        opacity: 0;
        top: 100%;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #1367FF;
        transition: opacity 0.6s ease-in-out;
    }

    &:hover {
        color: #1367FF;

        &:before {
            opacity: 1;
        }
    }
}

.active {
    color: #1367FF;
    &:before {
        position: absolute;
        content: '';
        opacity: 1;
        top: 100%;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: #1367FF;
        transition: opacity 0.6s ease-in-out;
    }
}