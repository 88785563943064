
.bunner {
    position: relative;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    height: 556px;
    @media (max-width: 1160px) {
        height: 100%;
        flex-direction: column-reverse;
    }
}

.bunnerContainer {
    max-width: 485px;
    width: 100%;
    @media (max-width: 1160px) {
        max-width: 100%;
    }
}

.title {
    max-width: 505px;
    font-size: 60px;
    font-weight: 600;
    line-height: 120%;
    color: #050505;
    padding-bottom: 15px;

    span {
        color: #1367FF;
    }
    @media (max-width: 1160px) {
        max-width: 100%;
        text-align: center;
        font-size: 50px;
        padding-top: 8px;
    }
    @media (max-width: 700px) {
        font-size: 40px;
        padding-top: 8px;
    }
    @media (max-width: 500px) {
        font-size: 30px;
        padding-top: 8px;
    }
}

.subtitle {
    font-size: 24px;
    font-weight: 400;
    line-height: 120%;
    color: #ABABAB;
    @media (max-width: 1160px) {
        text-align: center;
        font-size: 20px;
    }
    @media (max-width: 500px) {
        font-size: 14px;
    }
}

.contactus {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1367FF;
    border-radius: 50px;
    width: 300px;
    height: 56px;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
    color: #FFFFFF;
    transition: transform 0.5s ease-in-out;

    @media (max-width: 1160px) {
        margin-bottom: 48px;
        margin-left: auto;
        margin-right: auto;
        font-size: 15px;
        height: 46px;
    }
    @media (max-width: 500px) {
        width: 280px;
        height: 36px;
        margin-bottom: 48px;
        font-size: 14px;
    }

    &:hover {
        transform: translateY(2px);
    }
}

.bunnerImg {
    position: absolute;
    right: 0;
    top: 0;
    max-width: 720px;
    width: 100%;
    max-height: 556px;
    height: 100%;
    z-index: -1;

    img {
        width: 100%;
        height: 100%;
    }
    @media (max-width: 1160px) {
        position: static;
    }
}

.aboutus {
    background-color: #F6F6F6;
    width: 100%;
}

.aboutusContainer {
    padding: 90px 0 90px 0;
    display: flex;
    gap: 40px;
    align-items: center;
    @media (max-width: 1180px) {
        flex-wrap: wrap;
        justify-content: center;
    }
    @media (max-width: 900px) {
        padding: 45px 0 45px 0;
        gap: 35px;
    }
    @media (max-width: 500px) {
        padding: 25px 0 25px 0;
        gap: 30px;
    }
}

.aboutusInf {

    span {
        font-size: 24px;
        font-weight: 400;
        line-height: 120%;
        color: #ABABAB;
        @media (max-width: 900px) {
            font-size: 19px;
        }
        @media (max-width: 500px) {
            font-size: 14px;
        }
    }

    h2 {
        margin-top: 15px;
        font-size: 40px;
        font-weight: 600;
        line-height: 120%;
        color: #050505;
        @media (max-width: 1180px) {
            text-align: center;
        }
        @media (max-width: 900px) {
            font-size: 30px;
        }
        @media (max-width: 500px) {
            margin-top: 10px;
            font-size: 20px;
        }
    }

    p {
        margin-top: 60px;
        font-size: 18px;
        font-weight: 400;
        line-height: 120%;
        color: #6B6B6B;
        @media (max-width: 1180px) {
            text-align: center;
        }
        @media (max-width: 900px) {
            margin-top: 30px;
            font-size: 16px;
        }
        @media (max-width: 500px) {
            margin-top: 10px;
            font-size: 14px;
        }
    }

    @media (max-width: 1180px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.aboutusExample {
    display: flex;
    align-items: center;
    gap: 15px;
    max-width: 660px;
    width: 100%;

    div {
        display: flex;
        flex-direction: column;
        border-radius: 18px;

        span {
            margin-top: 30px;
            margin-bottom: 10px;
            font-size: 20px;
            font-weight: 600;
            line-height: 120%;
            @media (max-width: 900px) {
                margin-top: 22px;
                font-size: 17px;
                margin-bottom: 7px;
            }
            @media (max-width: 500px) {
                margin-top: 14px;
                font-size: 14px;
                margin-bottom: 5px;
            }
        }

        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 120%;
            @media (max-width: 900px) {
                font-size: 14px;
            }
            @media (max-width: 500px) {
                font-size: 12px;
            }
        }

        img {
            width: 44px;
            height: 44px;
            @media (max-width: 900px) {
                width: 34px;
                height: 34px;
            }
            @media (max-width: 500px) {
                width: 24px;
                height: 24px;
            }
        }

        @media (max-width: 500px) {
            border-radius: 8px;
        }
    }

    @media (max-width: 1180px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    @media (max-width: 500px) {
        gap: 10px;
    }
}

.guarantees {
    padding: 20px;
    width: 200px;
    background-color: #fff;

    span {
        color: #050505;
    }

    p {
        color: #6B6B6B;
    }

    @media (max-width: 1180px) {
        width: 90%;
    }
    @media (max-width: 500px) {
        padding: 15px 10px;
    }
}

.savings {
    padding: 35px 20px;
    width: 230px;
    background-color: #1367FF;

    span {
        color: #FFFFFF;
    }

    p {
        color: #FFFFFF;
    }

    @media (max-width: 1180px) {
        width: 90%;
    }
    @media (max-width: 500px) {
        padding: 15px 10px;
    }
}

.availability {
    padding: 20px;
    width: 200px;
    background-color: #fff;

    span {
        color: #050505;
    }

    p {
        color: #6B6B6B;
    }

    @media (max-width: 1180px) {
        width: 90%;
    }
    @media (max-width: 500px) {
        padding: 15px 10px;
    }
}

.advantagesTitle {
    margin-top: 80px;
    margin-bottom: 60px;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    line-height: 120%;
    color: #1D1D1B;
    @media (max-width: 900px) {
        margin-top: 60px;
        margin-bottom: 40px;
        font-size: 30px;
    }
    @media (max-width: 500px) {
        margin-top: 40px;
        margin-bottom: 20px;
        font-size: 20px;
    }
}

.advantagesCont {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 30px;
    @media (max-width: 1180px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(3, 1fr);

    }
    @media (max-width: 900px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(6, 1fr);
        grid-column-gap: 0;
        grid-row-gap: 30px;
    }

}

.advantagesExample {
    position: relative;
    display: flex;
    max-height: 205px;
    flex-direction: column;

    span {
        font-size: 20px;
        font-weight: 600;
        line-height: 120%;
        color: #050505;
        margin-top: 25px;
        margin-bottom: 5px;
        @media (max-width: 900px) {
            margin-top: 20px;
            font-size: 18px;
        }
        @media (max-width: 500px) {
            margin-top: 15px;
            font-size: 16px;
        }
    }

    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 120%;
        color: #6B6B6B;
        margin-bottom: 5px;
        @media (max-width: 900px) {
            font-size: 16px;
        }
        @media (max-width: 500px) {
            margin-bottom: 0;
            font-size: 14px;
        }
    }

    img {
        width: 40px;
        height: 40px;
        @media (max-width: 500px) {
           padding: 10px;
        }
    }

    &:before {
        position: absolute;
        content: '';
        right: -20px;
        top: 0;
        height: 100%;
        width: 2px;
        background-color: #E8E8E8;

    }
    @media (min-width: 1181px) {
        &:nth-child(3):before {
            display: none;
        }

        &:nth-child(6):before {
            display: none;
        }
    }
    @media (max-width: 900px) {
        &:before {
            width: 100%;
            height: 2px;
            top: calc(100% + 15px);
            left: 0;

        }


    }

}

.cases {
    margin: 80px 0;
    @media (max-width: 900px) {
        margin: 60px 0;
    }
    @media (max-width: 500px) {
        margin: 40px 0 57px 0;
    }
}

.slide {
    display: flex !important;
    flex-direction: column;
    height: 185px !important;
    width: 286px !important;
    background-color: #F6F6F6;
    border-radius: 18px;
    padding: 35px;

    span {
        margin-top: 30px;
        font-size: 20px;
        font-weight: 600;
        line-height: 120%;
        color: #050505;
        margin-bottom: 5px;
        @media (max-width: 900px) {
            margin-top: 22px;
            font-size: 18px;
        }
        @media (max-width: 500px) {
            margin-top: 15px;
            font-size: 16px;
        }
    }

    p {
        font-size: 18px;
        font-weight: 400;
        line-height: 120%;
        color: #6B6B6B;
        @media (max-width: 900px) {
            font-size: 16px;
        }
        @media (max-width: 500px) {
            font-size: 14px;
        }
    }

    div {
        display: flex;
        align-items: center;
        justify-content: start;
        height: 60px;
        img {
            height: calc(100%);
        }
        @media (max-width: 900px) {
            height: 50px;
        }
        @media (max-width: 500px) {


            height: 40px;
        }
    }
    @media (max-width: 900px) {
        padding: 20px;
        height: 185px !important;
        width: 286px !important;
    }
    @media (max-width: 500px) {
        padding: 10px;
        height: 150px !important;
        width: 260px !important;
    }
}

.casesContainer {
    position: relative;
}

.mySwiper {
    width: calc(100% - 70px);
    @media (max-width: 500px) {
        width: 100%;
    }
}

.bunnerContactUs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: calc(100% - 30px);
    padding: 60px 15px;
    background: linear-gradient(#5390FF, #1367FF);
    border-radius: 20px;

    h2 {
        font-size: 40px;
        text-align: center;
        font-weight: 600;
        line-height: 120%;
        color: #FFFFFF;
        margin-bottom: 10px;
        @media (max-width: 900px) {
            font-size: 30px;
        }
        @media (max-width: 500px) {
            font-size: 20px;
            margin-bottom: 15px;
        }
    }

    p {
        text-align: center;
        max-width: 640px;
        font-size: 18px;
        font-weight: 400;
        line-height: 120%;
        color: #FFFFFF;
        margin-bottom: 40px;
        @media (max-width: 900px) {
            font-size: 16px;
            margin-bottom: 33px;
        }
        @media (max-width: 500px) {
            font-size: 14px;
            margin-bottom: 25px;
        }
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FFFFFF;
        border-radius: 50px;
        width: 260px;
        height: 56px;
        font-size: 16px;
        font-weight: 500;
        line-height: 120%;
        color: #1367FF;
        transition: transform 0.5s ease-in-out;

        &:hover {
            transform: translateY(2px);
        }
        @media (max-width: 900px) {
            width: 240px;
            height: 46px;
            font-size: 15px;
        }
        @media (max-width: 500px) {
            width: 226px;
            height: 36px;
            font-size: 14px;
        }
    }
    @media (max-width: 900px) {
        padding: 40px 15px;
    }
    @media (max-width: 500px) {
        padding: 20px 15px;
    }

}

.blackBackg {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 126px;
    background: #1D1D1B;
    z-index: -1;
}