
.title {
    margin-top: 35px;
    margin-bottom: 50px;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    line-height: 120%;
    color: #1D1D1B;
    @media (max-width: 900px) {
        font-size: 25px;
        margin-bottom: 40px;
    }
    @media (max-width: 500px) {
        margin-top: 40px;
        margin-bottom: 30px;
        font-size: 20px;
    }
}

.contacts {
    margin-bottom: 80px;
    display: flex;
    justify-content: space-between;
    gap: 40px;
    @media (max-width: 900px) {
        flex-direction: column;
        margin-bottom: 60px;
        gap: 25px;
    }
    @media (max-width: 500px) {
        margin-bottom: 40px;
        gap: 10px;
    }
}

.inform {
    display: flex;
    flex-direction: column;
}

.place {
    height: 135px;
    padding: 35px 20px 0 20px;
    background: #F6F6F6;
    border-radius: 15px;

    span {
        font-size: 18px;
        font-weight: 400;
        line-height: 120%;
        color: #ABABAB;
        @media (max-width: 900px) {
            font-size: 15px;
        }
        @media (max-width: 500px) {
            font-size: 12px;
        }
    }

    p {
        max-width: 410px;
        margin-top: 16px;
        font-size: 18px;
        font-weight: 400;
        line-height: 120%;
        color: #000000;
        @media (max-width: 900px) {
            font-size: 15px;
        }
        @media (max-width: 500px) {
            font-size: 12px;
        }
    }
    @media (max-width: 900px) {
        height: 100px;
        padding: 17px;
    }
    @media (max-width: 500px) {
        height: 82px;
        padding: 15px;
    }
}

.email {
    display: flex;
    flex-direction: column;
    height: 135px;
    margin-top: 10px;
    padding: 35px 20px 0 20px;
    background: #F6F6F6;
    border-radius: 15px;

    span {
        font-size: 18px;
        font-weight: 400;
        line-height: 120%;
        color: #ABABAB;
        @media (max-width: 900px) {
            font-size: 15px;
        }
        @media (max-width: 500px) {
            font-size: 12px;
        }
    }

    a {
        margin-top: 16px;
        font-size: 18px;
        font-weight: 400;
        line-height: 120%;
        color: #0f43ff;
        &:hover {
            color: #8099f6;
        }
        @media (max-width: 900px) {
            font-size: 15px;
        }
        @media (max-width: 500px) {
            margin-top: 10px;
            font-size: 12px;

        }
    }

    @media (max-width: 900px) {
        height: 100px;
        padding: 17px;
    }
    @media (max-width: 500px) {
        height: 82px;
        padding: 15px;
    }
}

.textContact {
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    color: #ABABAB;
    @media (max-width: 900px) {
        font-size: 15px;
    }
    @media (max-width: 500px) {
        font-size: 12px;
    }
}

.name {
    font-size: 30px;
    font-weight: 600;
    line-height: 120%;
    color: #1367FF;
    @media (max-width: 900px) {
        text-align: center;
        font-size: 22px;
    }
    @media (max-width: 500px) {
        font-size: 14px;
    }
}

.line {
    position: relative;
    margin-top: 18px;
    margin-bottom: 20px;
    width: 100%;
    height: 2px;
    background: #1367FF;

    &:after {
        position: absolute;
        content: '';
        height: 16px;
        width: 17px;
        right: 0;
        bottom: 0;
        background-image: url("./../../assets/Polygon.svg");
    }

    @media (max-width: 900px) {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    @media (max-width: 500px) {
        margin-top: 12px;
        margin-bottom: 10px;
    }
}
