.map {
    max-width: 657px;
    width: 100%;
    height: 420px !important;
    border-radius: 20px;
    @media (max-width: 900px) {
        max-width: 100%;
    }
    @media (max-width: 800px) {
        height: 350px !important;
    }
    @media (max-width: 500px) {
        height: 254px !important;
    }
}