
.hamburger {
    position: relative;
    z-index: 6;
    @media (min-width: 701px) {
        display: none;
    }
}

.hamburgerBox {
    height: 40px;
    width: 40px;
}

.ham {
    margin-top: 6px;
    border-radius: 7999.2px;
    background: transparent;
    position: absolute;
    top: -15px;
    left: 0;
    z-index: 7;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 40px;
    height: 40px;
}

.hamRotate.active {
    transform: rotate(45deg);
}

.hamRotate180.active {
    transform: rotate(180deg);
}

.line {
    fill: none;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke: #1367FF;
    stroke-width: 4px;
    stroke-linecap: round;
}

.ham1 .top {
    stroke-dasharray: 40 139;
}

.ham1 .bottom {
    stroke-dasharray: 40 180;
}

.ham1.active .top {
    stroke-dashoffset: -98px;
}

.ham1.active .bottom {
    stroke-dashoffset: -138px;
}


.linkBtn {
    font-size: 20px;
    font-weight: 500;
    line-height: 170%;
    color: #1367FF;
    @media (max-width: 600px) {
        font-size: 16px;
    }
    @media (max-width: 500px) {
        font-size: 14px;
    }
}

.email {
    width: fit-content;
    font-size: 20px;
    font-weight: 500;
    line-height: 170%;
    color: #B3B3B3;
    margin-top: 20px;
    @media (max-width: 600px) {
        font-size: 16px;
    }
    @media (max-width: 500px) {
        font-size: 14px;
    }
}

.linkContainer {
    margin-top: 155px;
    display: flex;
    gap: 10px;
    width: fit-content;
    flex-direction: column;
}

.background {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(19, 66, 152, 0.8);
    z-index: 2;
    opacity: 0;
}
