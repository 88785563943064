@import url('https://fonts.googleapis.com/css2?family=Onest:wght@100..900&display=swap');

body {
  font-family: "Onest", sans-serif;
  font-style: normal;

}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  text-decoration: none;
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.container {
  max-width: 1160px;
  margin: 0 auto;
  padding: 0 15px;
}

.swiper {
  position: static !important;
  width: 100%;
  height: 100%;
  margin-left: 35px !important;
  margin-right: 35px !important;
  @media (max-width: 500px) {
    margin-left: 0 !important;
    margin-right: 0 !important;  }
}

.swiper-button-prev {
  left: 0 !important;
  cursor: pointer;
  height: 35px !important;
  width: 35px !important;
  &:after {
    position: absolute;
    content: '' !important;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("./assets/arrow-left.svg");
  }
  @media (max-width: 500px) {
    display: none !important;
  }
}

.swiper-button-next {
  right: 0 !important;
  cursor: pointer;
  height: 35px !important;
  width: 35px !important;
  &:after {
    position: absolute;
    content: '' !important;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url("./assets/arrow-right.svg");
  }
  @media (max-width: 500px) {
    display: none !important;
  }
}

.swiper-pagination  {
  @media (min-width: 501px) {
    display: none !important;
  }
  top: 105% !important;
}

.no-scrollbar {
  overflow: hidden;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@keyframes checked-anim {
  50% {
    width: 100%;
    height: calc(40% + 50px);
  }
  100% {
    width: 100%;
    height: calc(40% + 50px);
    border-radius: 0;
  }
}

@keyframes not-checked-anim {
  0% {
    width: 100%;
    height: calc(40% + 50px);
  }
}


#trigger, #burger, #burger:before, #burger:after {
  position: absolute;
  top: 0;
  width: 40px;
  height: 40px;
  transition: .6s ease;
  cursor: pointer;
  z-index: 112;

}

#menu {
  position: fixed;
  top: calc(-100% - 50px);
  left: 0;
  margin: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  background-color: #FFFFFF;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  transition: .6s;
  display: flex;
  gap: 30px;
  flex-direction: column;
  z-index: 100;
}


@keyframes checked-anim-image {
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;

  }
}

@keyframes not-checked-anim-image {
  0% {
    opacity: 0;
  }
}


@keyframes checked-anim-modal {
  50% {
    width: 100%;
    height: fit-content;
  }
  100% {
    width: 100%;
    height: fit-content;
  }
}

@keyframes not-checked-anim-modal {
  0% {
    width: 100%;
    height: fit-content;
  }
}