.footer {
    background: #1D1D1B;
    width: 100%;
    padding: 69px 0 79px 0;
    @media (max-width: 900px) {
        padding: 54px 0 39px 0;
    }
    @media (max-width: 500px) {
        padding: 44px 0 29px 0;
    }
}

.footerContainer {
    display: flex;
    gap: 20px;
    flex-direction: column;
    @media (max-width: 600px) {
        align-items: center;
    }
}

.topCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
        width: 124px;
        height: 54px;
        @media (max-width: 600px) {
            width: 106px;
            height: 47px;
        }
    }
    @media (max-width: 600px) {
       flex-direction: column;
        gap: 20px;
    }
}

.line {
    width: 100%;
    height: 1px;
    background: #FFFFFF;
}

.company {
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    color: #B3B3B3;
    @media (max-width: 900px) {
        font-size: 12px;
    }
    @media (max-width: 500px) {
        font-size: 10px;
    }
}
.list {
    display: flex;
    align-items: center;
    gap: 50px;
    @media (max-width: 600px) {
        flex-direction: column;
        gap: 15px;
    }
}

.link {
    font-size: 18px;
    font-weight: 400;
    line-height: 120%;
    color: #B3B3B3;
    @media (max-width: 900px) {
        font-size: 16px;
    }
    @media (max-width: 500px) {
        font-size: 12px;
    }
}