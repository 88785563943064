
.title {
    margin-top: 35px;
    margin-bottom: 15px;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    line-height: 120%;
    color: #1D1D1B;
    @media (max-width: 900px) {
        font-size: 30px;
    }
    @media (max-width: 500px) {
        font-size: 20px;
        margin-top: 40px;
        margin-bottom: 10px;
    }
}

.subtitle {
    max-width: 775px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 120%;
    color: #ABABAB;
    margin-bottom: 50px;
    @media (max-width: 900px) {
        font-size: 16px;
    }
    @media (max-width: 500px) {
        font-size: 12px;
        margin-bottom: 20px;
    }
}

.services {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.servicesCont {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    margin-bottom: 80px;
    @media (max-width: 950px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, 1fr);
        margin-bottom: 60px;
        grid-column-gap: 0;
        grid-row-gap: 25px;
    }
    @media (max-width: 500px) {
        margin-bottom: 40px;
        grid-column-gap: 0;
        grid-row-gap: 15px;
    }
}

.servicesExample {
    position: relative;
    background: #F6F6F6;
    border-radius: 20px;
    padding: 20px;
    height: 204px;
    @media (max-width: 900px) {
        height: 184px;
    }
    @media (max-width: 500px) {
        padding: 15px;
    }
}

.topCont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;

    h4 {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 600;
        line-height: 120%;
        color: #050505;
        @media (max-width: 900px) {
            font-size: 16px;
        }
        @media (max-width: 500px) {
            font-size: 13px;
        }
    }
    @media (max-width: 900px) {
        margin-bottom: 20px;
    }
    @media (max-width: 500px) {
        margin-bottom: 10px;
    }
}

.information {
    font-size: 18px;
    font-weight: 400;
    line-height: 120%;
    color: #6B6B6B;
    @media (max-width: 900px) {
        font-size: 16px;
    }
    @media (max-width: 500px) {
        font-size: 13px;
    }
}

.button {
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1367FF;
    border-radius: 50px;
    width: 186px;
    height: 48px;
    font-size: 16px;
    font-weight: 500;
    line-height: 120%;
    color: #FFFFFF;
    border: none;
    outline: none;
    cursor: pointer;
    transition: transform 0.5s ease-in-out;
    &:hover {
        transform: translateY(2px);
    }
    @media (max-width: 900px) {
        font-size: 15px;
    }
    @media (max-width: 500px) {
        font-size: 14px;
        width: calc(100% - 30px);
    }
}

.modal {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.blur {
    position: fixed;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(4px) brightness(0.8);
    z-index: 100;
}


.modalCont {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 662px;
    left: 50%;
    max-height: 70%;
    height: fit-content;
    width: calc(100% - 40px);
    background: #FFFFFF;
    border-radius: 20px;
    flex-direction: column;
    padding: 20px 31px;
    z-index: 10000;

    @media (min-width: 901px) {
        top: 50%;
        transform: translate(-50%, -50%);
    }

    @media (max-width: 900px) {
        position: fixed;
        bottom: 0;
        left: 0;
        max-width: calc(100% - 40px);
        width: 100%;
        height: auto;
        max-height: 80%;
        overflow-y: auto;
        padding: 20px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        transition: bottom 0.6s ease;
    }
}

.close {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 24px;
    height: 24px;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    z-index: 2;
}

.modalSubTitle {
    text-align: center;
    font-size: 15px;
    font-weight: 400;
    line-height: 120%;
    color: #ABABAB;
    margin-bottom: 3px;
    @media (max-width: 900px) {
        font-size: 14px;
    }
    @media (max-width: 500px) {
        font-size: 12px;
        margin-bottom: 5px;
    }
}

.modalTitle {
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
    line-height: 120%;
    color: #050505;
    margin-bottom: 20px;
    @media (max-width: 900px) {
        font-size: 18px;
    }
    @media (max-width: 500px) {
        font-size: 14px;
    }
}

.example {
    padding-right: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: auto;
    overflow-x: hidden;
}

.example::-webkit-scrollbar {
    width: 4px;
    background-color: #ECEEF1;
}

.example::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #A1A9B8;
}

.example::-webkit-scrollbar-track {
    border-radius: 1px;
    background-color: #ECEEF1;
}

.decisions {
    display: flex;
    align-items: center;
    gap: 20px;
    width: calc(100% - 40px);
    border: 1px #ECEEF1 solid;
    border-radius: 15px;
    padding: 10px 20px;
    span {
        font-size: 16px;
        font-weight: 300;
        line-height: 120%;
        color: #000000;
        @media (max-width: 900px) {
            font-size: 14px;
        }
        @media (max-width: 500px) {
            font-size: 12px;
        }
    }

    @media (max-width: 900px) {
        width: calc(100% - 30px);
        padding: 9px 15px;
        gap: 15px;
    }
    @media (max-width: 500px) {
        width: calc(100% - 20px);
        gap: 10px;
        padding: 7px 10px;

    }
}